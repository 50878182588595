var currentPath = window.location.pathname //+ window.location.search;
var currentSearch = encodeURIComponent(window.location.search);
var get_url_string = window.location.href;
var get_url = new URL(get_url_string);
var is_get_params = get_url.searchParams.get("page");
var get_params = encodeURIComponent(is_get_params)
var hash = location.hash;
if($('#halamanLapsusValidasi').length){
    var login_lapsus_utm = '%3Futm_campaign%3Dlogin-lapsus%26utm_medium%3Dlogin-lapsus%26utm_source%3Dwebsite%26utm_content%3Dlogin-artikel-lapsus%26utm_term%3Dlogin-lapsus'
    var register_lapsus_utm = '%3Futm_campaign%3Dregister-lapsus%26utm_medium%3Dregister-lapsus%26utm_source%3Dwebsite%26utm_content%3Dregister-artikel-lapsus%26utm_term%3Dregiter-lapsus'
    $('#lapsus-premium-login').attr('href','/login?page='+currentPath+login_lapsus_utm+hash)
    $('#lapsus-premium-register').attr('href','/register?page='+currentPath+register_lapsus_utm+hash)
}
if(is_get_params){
  $('#open-login, #burger-login-button').attr('href','/login?page='+get_params+hash)
  $('#login-overlay-register-button').attr('href','/register?page='+get_params+hash)
}else{
  $('#open-login, #burger-login-button').attr('href','/login?page='+currentPath+currentSearch+hash)
  $('#login-overlay-register-button').attr('href','/register?page='+currentPath+currentSearch+hash)
}

$('#overlay-login-button').attr('href','/login?page='+currentPath+currentSearch+hash)
$('#overlay-register-button').attr('href','/register?page='+currentPath+currentSearch+hash)
$('#current-page-logout').attr('href',currentPath+window.location.search+hash)

var showLoginOverlay = function() {
  $('#overlay-login-redirect').removeClass('collapse')
}

$('#overlay-login-redirect').click(function(event) {
    if (event.target === this) {
        $('#login-redirect').parent().addClass('collapse');
    }
});
$('#overlay-login-button').click(function(event) {
    window.location = '/login?page='+currentPath+currentSearch
});
$('#login-redirect .close-modal').click(function(event) {
    $('#login-redirect').parent().addClass('collapse');
});
